/* responsiveFacets */
define(['app', '$window', '$console', 'accordionWidget', 'accessibleModalHelper', 'accessibilityFocusHelper', 'siteObj'], (app, $window, $console, accordion, accessibleModalHelper, accessibilityFocusHelper, siteObj) => () => {
  const _breakpoint = 900;
  const _noScroll = 'responsiveFacets_noScroll';
  const _containerTransitioned = 'responsiveFacets_container-transitioned';
  const _errorDuration = 3 * 1000;
  const PRICE_FACET_DELIMITER = ':';

  const _select = {
    checkbox: '.responsiveFacets_sectionItemCheckbox',
    sectionHead: '.responsiveFacets_sectionHead',
    mobileSectionHead: '.responsiveFacets_sectionHead-mobile',
    sectionContainer: '.responsiveFacets_sectionContainer',
    sectionItemContainer: '.responsiveFacets_sectionItemLabel',
    sectionItem: '.responsiveFacets_sectionItem',
    sectionClear: '.responsiveFacets_sectionClear',
    sectionTitle: '.responsiveFacets_sectionTitle',
    selection: '.responsiveFacets_selection',
    clearAll: '.responsiveFacets_clearAll',
    refine: '.responsiveFacets_refine',
    container: '.responsiveFacets_container',
    saveContainer: '.responsiveFacets_saveContainer',
    save: '.responsiveFacets_save',
    sectionBackArrow: '.responsiveFacets_sectionBackArrow',
    menuClose: '.responsiveFacets_menuClose',
    error: '.responsiveFacets_error',
    mobileRefineTitle: '#responsive-facets-title',
    mobileSectionTitle: '.responsiveFacets_mobileSectionTitle',
    sectionInteractiveElements: 'button, a',
    mobileSectionInteractiveElements: 'button:not(.responsiveFacets_sectionHead), input[type="checkbox"]',
    sectionContent: '.responsiveFacets_sectionContent',
    goToProductSectionButton: '.responsiveFacets_goToProductSectionButton',
    productTitle: '#responsive-product-list-title',
    priceFacet: '[data-component=priceFacet]',
    priceFacetErrorMessages: '.responsiveFacets_priceErrorMessageWrapper',
    selectionContainer: '.responsiveFacets_selectionContainer',
    priceFacetSectionCount: '.priceFacetSectionCount',
    priceFacetErrorIcon: '.responsiveFacets_priceErrorIcon',
    productListWrapper: '[data-product-list-wrapper]',
  };

  const _attr = {
    key: 'data-facet-key',
    value: 'data-facet-value',
    show: 'data-show',
    closed: 'data-closed',
    selected: 'data-selected',
    ariaHidden: 'aria-hidden',
    fieldName: 'data-field-name',
    selectedValue: 'data-selected-value',
    priceFacet: 'data-price-facet',
    childOpen: 'data-child-open',
    selectedFacet: 'data-selected-facet',
    mobileFacetSelected: 'mobile-facet-selected',
    selectionOrAllCleared: 'selection-all-cleared',
    sectionCleared: 'section-cleared',
    indexableFacets: 'data-indexable-facets'
  };

  const _ids = {
    mobileRefineTitle: 'responsive-facets-title',
  };

  const _facetKeys = {
    brand: 'en_brand_content',
    colour: 'en_hbg_baseColour_content',
    size: 'en_hbg_sizeFacet_content',
    type: 'en_hbg_type_content'
  };

  const _facetFilterType = {
    brand: 'brand',
    colour: 'colour',
    size: 'size',
    type: 'type',
    other: 'other'
  };

  const _channels = {
    update: 'responsiveProductListPage/update',
    priceFacet: 'responsiveFacets/priceFacet',
    responsiveFacetsError: 'responsiveProducts/responsiveFacets',
    priceRange: 'priceFacet/mobileRange',
    priceReset: 'priceFacet/mobileReset',
    restorePreviousSelections: 'responsiveFacets/prevSelections',
    shopBySwatchFacetClick: 'shopBySwatchFacetClick'
  };

  const _accordionConfig = {
    isOpen: true,
    selectors: {
      title: '.responsiveFacets_sectionHead',
      content: '.responsiveFacets_sectionContent',
      focusableElements: 'input[type="checkbox"]',
    },
    offset: 1,
    tracking: {
      enabled: true,
      channel: 'responsiveFacets',
    },
  };
  const _selectors = {
    fastTrackSwitch: '.fastTrack_onListPage',
    fastTrackSectionItem: '.responsiveFacets_sectionItem_fastTrack',
    fastTrackCheckbox: 'fastTrackCheckbox',
    fastTrackFacetCategory: 'fastTrackFacetCategory',
    fastTrackDeliverableFacet: 'fastDeliverableCountries',

  };
  const _elems = {
    items: [],
    sectionContainers: [],
    container: null,
    refine: null,
    save: null,
    checkboxes: [],
    sectionHeads: [],
    sectionTitles: [],
    selections: [],
    clearAll: null,
    backArrows: [],
    menuCloses: [],
    error: null,
  };

  const _mobile = {
    show: false,
    section: -1,
  };

  const comp = {
    element: null,
    changes: false,
    facets: [],
    // testing
    _elems,
    _mobile,
    _attr,
    _channels,
    _noScroll,
    _breakpoint,
  };

  // To prevent IE 11 error log
  let blankChangeEvent;
  if (typeof (Event) === 'function') {
    blankChangeEvent = new Event('change');
  } else {
    blankChangeEvent = document.createEvent('Event');
    blankChangeEvent.initEvent('change', false, false);
  }
  // IE fix end

  /**
   * Init
   *
   * @param {HTMLElement} element
   */
  comp.init = function (element, userDriven) {
    comp.element = element;
    comp.changes = false;
    _elems.priceFacet = comp.element.querySelector(_select.priceFacet);
    _elems.fastTrackSwitch = document.querySelector(_selectors.fastTrackSwitch);
    _elems.sectionItemContainers = element.querySelectorAll(_select.sectionItemContainer);
    _elems.items = element.querySelectorAll(_select.sectionItem);
    _elems.container = element.querySelector(_select.container);
    _elems.refine = element.querySelector(_select.refine);
    _elems.saveContainer = element.querySelector(_select.saveContainer);
    _elems.save = element.querySelector(_select.save);
    _elems.sectionContainers = element.querySelectorAll(_select.sectionContainer);
    _elems.checkboxes = element.querySelectorAll(_select.checkbox);
    _elems.sectionHeads = element.querySelectorAll(_select.mobileSectionHead);
    _elems.sectionTitles = element.querySelectorAll(_select.sectionTitle);
    _elems.selections = element.querySelectorAll(_select.selection);
    _elems.clearAll = element.querySelector(_select.clearAll);
    _elems.backArrows = element.querySelectorAll(_select.sectionBackArrow);
    _elems.menuCloses = element.querySelectorAll(_select.menuClose);
    _elems.error = element.querySelector(_select.error);
    _elems.mobileRefineTitle = element.querySelector(_select.mobileRefineTitle);

    _elems.productTitle = document.querySelector(_select.productTitle);
    _elems.goToProductSectionButtons = document.querySelectorAll(_select.goToProductSectionButton);
    _elems.priceFacetErrorMessages = comp.element.querySelectorAll(_select.priceFacetErrorMessages);
    _elems.selectionContainer = comp.element.querySelector(_select.selectionContainer);
    _elems.priceFacetSectionCount = comp.element.querySelector(_select.priceFacetSectionCount);
    _elems.priceErrorIcon = comp.element.querySelector(_select.priceFacetErrorIcon);
    _elems.wrapper = document.querySelector(_select.productListWrapper);
    _elems.indexableFacetsEnabled = element.getAttribute(_attr.indexableFacets);

    if (_elems.priceFacet) {
      _elems.priceFacetFieldName = _elems.priceFacet.getAttribute(_attr.fieldName);
      _elems.priceFacetIndex = Array.from(_elems.sectionContainers)
        .findIndex(section => section.hasAttribute(_attr.priceFacet));
    }

    if (!_elems.container) {
      return;
    }

    _elems.allInteractiveElements = _elems.container.querySelectorAll(_select.sectionInteractiveElements);

    if (!userDriven) {
      comp.subscribe();
    }
    comp.build(true);
    comp.bind();
    comp.activate();

    // because this component is reinitialised when there's a change to the page,
    // we want to open/close the facets given the state it currently has
    if (_mobile.show) {
      comp.open();
    } else {
      // this removes the left-over noscroll class on body
      comp.close();
    }

    const selectedFacet = _elems.wrapper.getAttribute(_attr.selectedFacet);
    if (selectedFacet) {
      const lastSelectedCheckbox = Array.from(_elems.checkboxes).find(cb => cb.getAttribute(_attr.value) === selectedFacet);
      lastSelectedCheckbox && lastSelectedCheckbox.focus();
      _elems.wrapper.removeAttribute(_attr.selectedFacet);
    }

    const mobileFacetSelected = _elems.wrapper.getAttribute(_attr.mobileFacetSelected);
    if (mobileFacetSelected) {
      _elems.refine.focus();
      _elems.wrapper.removeAttribute(_attr.mobileFacetSelected);
    }

    if (_elems.wrapper.getAttribute(_attr.selectionOrAllCleared)) {
      accessibilityFocusHelper.focus(_elems.container);
      _elems.wrapper.removeAttribute(_attr.selectionOrAllCleared);
    }

    if(_elems.indexableFacetsEnabled === 'true') {
      _elems.useIndexableFacets = true;
    }

    const clearedSectionIndex = _elems.wrapper.getAttribute(_attr.sectionCleared);
    if (clearedSectionIndex) {
      accessibilityFocusHelper.focus(_elems.sectionContainers[parseInt(clearedSectionIndex)]);
      _elems.wrapper.removeAttribute(_attr.sectionCleared);
    }

    _elems.sectionContainers.forEach(container => container.addEventListener('keydown', (event) => {
      if (event.key === 'Esc' || event.key === 'Escape') {
        const sectionHead = container.querySelector(_select.sectionHead);
        if (!sectionHead) return;

        if (sectionHead.disabled) {
          const sectionClear = container.querySelector(_select.sectionClear);
          sectionClear && sectionClear.focus();
        } else {
          sectionHead.focus();
        }
      }
    }))
  };

  comp.subscribe = function () {
    app.clear(_channels.priceFacet);
    app.clear(_channels.responsiveFacetsError);
    app.clear(_channels.restorePreviousSelections);

    app.subscribe(_channels.priceFacet, comp.updateWithPriceChanges);
    app.subscribe(_channels.responsiveFacetsError, comp.emptyResults);
    app.subscribe(_channels.restorePreviousSelections, comp.restorePreviousSelections);
    app.subscribe(_channels.shopBySwatchFacetClick, comp.facetClick);
  };

  comp.emptyResults = () => {
    _mobile.show = true;
    comp.togglePriceFacetErrorState();
    app.publish(_channels.priceRange);
  };

  comp.restorePreviousSelections = (previousSelection) => {
    comp.changes = false;
    comp.facets = comp.facets
      .filter(f => f.split(':')[0] !== previousSelection[0].split(':')[0])
      .concat(previousSelection);
  };

  comp.getFacetItems = () => {
    //return checked non-null responsiveFacet section items
    return Array.from(_elems.sectionItemContainers).map(itemContainer => {
      const checkbox = itemContainer.querySelector(_select.checkbox);
      if (!checkbox.checked) {
        return null;
      }

      return itemContainer.querySelector(_select.sectionItem);
    }).filter(f => f !== null);
  };

  comp.buildFacets = () => {
    // map each item to its facet value and filter empty values
    comp.facets = comp.getFacetItems().map(item => {
      const key = item.getAttribute(_attr.key);
      const value = item.getAttribute(_attr.value);

      return `${key}:${value}`;
    });
  };

  comp.buildIndexableFacets = (updateCompFacets, facetToFilter, isPriceFacet) => {
    let colour = [];
    let brand = [];
    let size = [];
    let type = [];
    let other = [];
    let key, value, filterType;

    comp.getFacetItems()
    .filter(facetItem => {
      if (facetToFilter === undefined) {
        return facetItem;
      } else {
        // When the user clicks on the x to clear a selected facet on the list page, we want to also remove it here
        // because this list is not up-to-date yet.
        // Also, we filter by key alone when facetToFilter is a price facet to keep this function consistent with the logic in
        // comp.clearSelectionListener; where we call this method with facetToFilter and isPriceFacet.
        // For all other facts we filter by key and value
        return isPriceFacet
            ? facetItem.getAttribute(_attr.key) !== facetToFilter.key
            : `${facetItem.getAttribute(_attr.key)}${facetItem.getAttribute(_attr.value)}` !== `${facetToFilter.key}${facetToFilter.value}`;
      }
    })
    .forEach(item => {
      key = item.getAttribute(_attr.key);
      value = item.getAttribute(_attr.value);
      filterType = comp.getFilterType(key);

      switch(filterType) {
        case _facetFilterType.colour:
          colour.push(`${key}:${value}`);
          break;
        case _facetFilterType.brand:
          brand.push(`${key}:${value}`);
          break;
        case _facetFilterType.size:
          size.push(`${key}:${value}`);
          break;
        case _facetFilterType.type:
          type.push(`${key}:${value}`);
          break;
        case _facetFilterType.other:
          other.push(`${key}:${value}`);
          break;
        default:
          other.push(`${key}:${value}`);
      }
    });

    if (updateCompFacets) {
      comp.facets = colour.concat(brand, size, type, other);
    }

    if (!colour.length && !brand.length && !size.length && !type.length) {
      comp.hasIndexableFacets = false;
    } else {
      comp.hasIndexableFacets = colour.length <= 1 && brand.length <= 1 && size.length <= 1 && type.length <= 1 && other.length === 0;
    }
  };

  /**
   * Builds facets and accordions.
   */
  comp.build = function (fromSwatch) {
    if (_elems.useIndexableFacets) {
      comp.buildIndexableFacets(true);
    } else {
      comp.hasIndexableFacets = false;
      comp.buildFacets();
    }

    const shopBySwatch = document.querySelector('.shopBySwatch');
    if(shopBySwatch && !fromSwatch){
      app.publish('shopBySwatch/facetUpdate', comp.facets)
    }

    // initialise accordions for sections without any selections
    _elems.sectionContainers.forEach((container, index) => {
      if (container.getAttribute(_attr.selected) === 'true') {
        return;
      }

      // make a new object so each accordion has a different config
      const sectionTitle = _elems.sectionTitles[index].innerText;
      const config = Object.assign({}, _accordionConfig);

      config.tracking = Object.assign({}, config.tracking);
      config.tracking.extra = sectionTitle;

      container.componentObject = new accordion().init(container, config);
    });

    if (_elems.priceFacet) {
      comp.appendPriceFacetFilters();
    }
  };

  comp.appendPriceFacetFilters = () => {
    if (_elems.priceFacet.getAttribute(_attr.selected) === 'true') {
      let fieldName = _elems.priceFacet.getAttribute(_attr.fieldName);
      let selectedValue = _elems.priceFacet.getAttribute(_attr.selectedValue);
      comp.facets.push(`${fieldName}:${selectedValue}`);
    }
  };

  comp.updateWithPriceChanges = (selection, update, clear) => {
    comp.facets = comp.facets.filter((facet) =>
      selection[0].split(PRICE_FACET_DELIMITER)[0] !== facet.split(PRICE_FACET_DELIMITER)[0]);
    if (clear) {
      comp.changes = update;
    } else {
      comp.changes = true;
      comp.facets = comp.facets.concat(selection);
    }
  };

  /**
   * Binds event listeners to elements.
   */
  comp.bind = function () {
    _elems.checkboxes.forEach((c, i) => c.addEventListener('change', comp.facetClick.bind(this, i, false)));
    _elems.sectionHeads.forEach((h, i) => h.addEventListener('click', comp.openSection.bind(this, i)));

    _elems.selections.forEach(selection => selection.addEventListener('click', comp.clearSelectionListener));
    _elems.backArrows.forEach(arrow => arrow.addEventListener('click', comp.back));
    _elems.menuCloses.forEach(closeArrow => closeArrow.addEventListener('click', comp.close));
    if (_elems.fastTrackSwitch) {
      _elems.fastTrackSwitch.addEventListener('change', comp.fastTrackSwitchChange);
    }
    if (_elems.clearAll) {
      _elems.clearAll.addEventListener('click', comp.clearAll);
    }

    $window.addEventListener('resize', comp.resize);
    _elems.save.addEventListener('click', comp.save);
    _elems.refine.addEventListener('click', comp.open);

    _elems.goToProductSectionButtons.forEach(button => button.addEventListener('click', comp.skipToProductSection));

    _elems.sectionContainers.forEach((container, index) => {
      const clearEl = container.querySelector(_select.sectionClear);

      if (clearEl) {
        clearEl._index = index;
        clearEl.addEventListener('click', comp.clearSectionListener);
      }
    });
  };

  /**
   * Activate transition on the container.
   */
  comp.activate = function () {
    // has to be deffered so it applies after comp.open is called in comp.init
    $window.setImmediate
      ? $window.setImmediate(() => {_elems.container.classList.add(_containerTransitioned)})
      : $window.setTimeout(() => {_elems.container.classList.add(_containerTransitioned)}, 0);
  };

  /**
   * Opens mobile facets.
   */
  comp.open = function (ev) {
    _elems.container.setAttribute('role', 'dialog');
    _elems.container.setAttribute('aria-labelledby', _ids.mobileRefineTitle);

    $window.document.body.classList.add(_noScroll);
    _elems.container.setAttribute(_attr.show, 'true');
    _elems.sectionContainers.forEach(section => section.setAttribute(_attr.closed, 'true'));

    // Start modal focus control and set starting element to title
    comp.accessibleModalHelper = new accessibleModalHelper(_elems.container, comp.close, _elems.mobileRefineTitle);

    // only track if the action is initiated by user
    // i.e. it has an event parameter
    if (ev) {
      app.publish('tracking/record', 'responsiveFacets', 'open', 'mobile facets');
    }

    _mobile.show = true;
  };

  /**
   * Closes mobile facets.
   */
  comp.close = function (priceFacet) {
    if (priceFacet && comp.priceFacetErrorDisplayed()) {
      app.publish(_channels.priceReset);
      comp.togglePriceFacetErrorState();
      _mobile.section = _elems.priceFacetIndex;
    }

    _elems.container.removeAttribute('role');
    _elems.container.removeAttribute('aria-labelledby');

    // Enable elements for desktop
    accessibilityFocusHelper.enableElements(_elems.allInteractiveElements);

    $window.document.body.classList.remove(_noScroll);
    _elems.container.setAttribute(_attr.show, 'false');
    _elems.container.setAttribute(_attr.childOpen, 'false');
    _elems.sectionContainers.forEach(section => section.setAttribute(_attr.closed, 'false'));

    // desktop cannot close the facets
    // but comp.close is still called even if it's desktop
    if ($window.document.documentElement.clientWidth < _breakpoint) {
      app.publish('tracking/record', 'responsiveFacets', 'close', 'mobile facets');
      priceFacet && _elems.refine.focus();
    }

    // want to clear changes
    comp.changes = 0;

    if (_mobile.section !== -1) {
      _elems.sectionContainers[_mobile.section].setAttribute(_attr.show, 'false');
    }

    _mobile.show = false;

    if (comp.accessibleModalHelper) {
      comp.accessibleModalHelper.close();
      delete comp.accessibleModalHelper;
    }
  };

  /**
   * Saves changes and exits facets.
   */
  comp.save = function () {
    if (!comp.changes) {
      // exit when there are no changes to be made
      comp.close(comp.priceFacetErrorDisplayed());
      _elems.refine.focus();
    } else {
      _mobile.section = -1;
      _mobile.show = false;

      _elems.wrapper.setAttribute(_attr.mobileFacetSelected, 'true');
      comp.update(true);
    }
  };

  comp.priceFacetErrorDisplayed = () => {
    return Array.from(_elems.priceFacetErrorMessages).filter(m => !m.classList.contains('hide'))[0];
  };

  /**
   * Clears all facets.
   */
  comp.clearAll = function () {
    comp.checkAndUpdateFastTrackCookie();
    comp.facets = [];
    comp.changes = true;

    app.publish('tracking/record', 'responsiveFacets', 'remove', 'all facets');

    if ($window.document.documentElement.clientWidth > _breakpoint) {
      _elems.wrapper.setAttribute(_attr.selectionOrAllCleared, 'true');
    }
    comp.update(true);
  };

  comp.togglePriceFacetErrorState = () => {
    if (_elems.priceFacetErrorMessages) {
      Array.from(_elems.priceFacetErrorMessages).forEach(m => m.classList.toggle('hide'));
    }

    let priceFacetSelection = Array.from(_elems.selections)
      .filter(selection => selection.getAttribute(_attr.key) === _elems.priceFacetFieldName)[0];
    if (priceFacetSelection) {
      priceFacetSelection.classList.toggle('hide');
      if (_elems.selections.length === 1) {
        _elems.selectionContainer.classList.toggle('hide');
      }
    }

    if (_elems.priceFacetSectionCount) {
      _elems.priceFacetSectionCount.classList.toggle('hide');
    }

    if (_elems.priceErrorIcon) {
      _elems.priceErrorIcon.classList.toggle('hide');
    }

  };

  /**
   * Return to first mobile page.
   */
  comp.back = function () {
    comp.indexUpdated = false;
    let sectionIndex = _mobile.section;

    // this only happens when price facet changes return empty result
    if (sectionIndex === -1) {
      app.publish(_channels.priceReset);
      comp.indexUpdated = true;
      sectionIndex = _elems.priceFacetIndex;
      comp.togglePriceFacetErrorState();
    }

    const sectionTitle = _elems.sectionTitles[sectionIndex].innerText;

    app.publish('tracking/record', 'responsiveFacets', 'close', 'mobile facets section', sectionTitle);

    const currentSection = _elems.sectionContainers[sectionIndex];

    currentSection.setAttribute(_attr.show, 'false');
    _elems.container.setAttribute(_attr.childOpen, 'false');

    currentSection.addEventListener('transitionend', function closeSectionAccessibly() {
      currentSection.setAttribute(_attr.closed, 'true');
      comp.accessibleModalHelper && comp.accessibleModalHelper.reload(_elems.container, _elems.mobileRefineTitle);
      currentSection.removeEventListener('transitionend', closeSectionAccessibly);
    });

    _mobile.section = -1;

    if (!comp.indexUpdated || comp.changes) {
      comp.update(true);
    }
  };

  /**
   * Opens a mobile facet section.
   *
   * @param {number} index
   */
  comp.openSection = function (index) {
    const sectionTitle = _elems.sectionTitles[index].innerText;

    app.publish('tracking/record', 'responsiveFacets', 'open', 'mobile facets section', sectionTitle);

    if (_mobile.section !== -1) {
      _elems.sectionContainers[_mobile.section].setAttribute(_attr.show, 'false');
      _elems.sectionContainers[_mobile.section].setAttribute(_attr.closed, 'true');
    }

    _elems.sectionTitles[index].setAttribute('aria-expanded', 'true');
    _elems.sectionContainers[index].setAttribute(_attr.show, 'true');
    _elems.sectionContainers[index].setAttribute(_attr.closed, 'false');

    _elems.container.addEventListener('transitionend', function openSectionAccessibly() {
      _elems.container.setAttribute(_attr.childOpen, 'true');
      comp.accessibleModalHelper && comp.accessibleModalHelper.reload(_elems.container, _elems.sectionContainers[index].querySelector(_select.mobileSectionTitle));
      _elems.container.removeEventListener('transitionend', openSectionAccessibly);
    });

    if (_elems.priceFacetIndex === index && comp.priceFacetErrorDisplayed()) {
      _mobile.section = -1;
    } else {
      _mobile.section = index;
    }
  };

  /**
   * Resize handler
   */
  comp.resize = function () {
    comp.update();
  };

  /**
   * Facet click handler
   *
   * @param {number} index
   * @param {boolean} fromSwatch
   */
  comp.facetClick = function (index, fromSwatch) {
    comp.checkAndUpdateFastTrackCookie(index, null);
    const item = _elems.items[index];

    const key = item.getAttribute(_attr.key);
    const value = item.getAttribute(_attr.value);

    let facet = `${key}:${value}`;

    app.publish('tracking/record', 'responsiveFacets', 'add', facet);

    if(siteObj.config.useGa4EnhancedEcom === true) {
      app.publish('ga4tracking/record/filter', 'custom_event', 'filter_applied', facet);
    }

    if(_elems.useIndexableFacets && _elems.checkboxes[index].checked) {
      comp.build(fromSwatch);
    } else if (_elems.checkboxes[index].checked) {
      comp.facets.push(facet);
    } else {
      comp.facets = comp.facets.filter(f => f !== facet);
      if(_elems.useIndexableFacets) {
        comp.build(false);
      }
    }

    comp.changes = true;
    let forceUpdate = false;
    if(fromSwatch) {
      forceUpdate = true;
    }
    if (item.classList.contains('fastTrack')) {
      comp.update(true);
    } else {
      comp.update(forceUpdate);
    }

    if ($window.document.documentElement.clientWidth < _breakpoint) {
      _elems.wrapper.setAttribute(_attr.mobileFacetSelected, 'true');
    } else {
      _elems.wrapper.setAttribute(_attr.selectedFacet, item.getAttribute(_attr.value));
    }
  };

  /**
   * Facet keyboard press handler
   *
   * @param {object} e
   * @param {int} i
   */
  comp.facetKeyboardPress = function (i, e) {
    if (e.key === ' ') {
      e.preventDefault();
      const hiddenCheckbox = _elems.checkboxes[i];
      hiddenCheckbox.checked = !hiddenCheckbox.checked;
      hiddenCheckbox.dispatchEvent(blankChangeEvent);
    }
  };

  /**
   * Clear section click handler
   *
   * @param {MouseEvent} ev
   */
  comp.clearSectionListener = function (ev) {
    const index = ev.currentTarget._index;

    if (!_elems.sectionContainers[index]) {
      $console.error(`Cannot clear non-existing section at index ${index}.`);
      return;
    }
    comp.checkAndUpdateFastTrackCookie(null, index);
    const sectionTitle = _elems.sectionTitles[index].innerText;

    app.publish('tracking/record', 'responsiveFacets', 'remove', 'facet group', sectionTitle);

    const section = _elems.sectionContainers[index];

    if (section.hasAttribute(_attr.priceFacet)) {
      comp.facets = comp.facets.filter(facet =>
        facet.split(PRICE_FACET_DELIMITER)[0] !== _elems.priceFacet.getAttribute(_attr.fieldName));
    } else {
      const facets = section.querySelectorAll(_select.sectionItem);

      const toRemove = Array.from(facets).map(facet => {
        const key = facet.getAttribute(_attr.key);
        const value = facet.getAttribute(_attr.value);

        return `${key}:${value}`;
      });

      comp.facets = comp.facets.filter(f => !toRemove.includes(f));
    }
    comp.changes = true;

    if ($window.document.documentElement.clientWidth > _breakpoint) {
      _elems.wrapper.setAttribute(_attr.sectionCleared, index.toString());
    }

    comp.update(true);
  };

  /**
   * Clear selection click handler
   *
   * @param {MouseEvent} ev
   */
  comp.clearSelectionListener = function (ev) {
    const selection = ev.currentTarget;

    const key = selection.getAttribute(_attr.key);
    const value = selection.getAttribute(_attr.value);
    const facet = `${key}:${value}`;
    const isPriceFacet = selection.hasAttribute(_attr.priceFacet);

    if (isPriceFacet) {
      comp.facets = comp.facets.filter(f => f.split(PRICE_FACET_DELIMITER)[0] !== key);
    } else {
      comp.facets = comp.facets.filter(f => f !== facet);
    }

    app.publish('tracking/record', 'responsiveFacets', 'remove', facet);
    if (facet && facet.includes(_selectors.fastTrackDeliverableFacet)) {
      comp.setCookie('fastTrackSwitchCookie', 'disableSwitch');
    }

    comp.changes = true;

    if ($window.document.documentElement.clientWidth > _breakpoint) {
      _elems.wrapper.setAttribute(_attr.selectionOrAllCleared, 'true');
    }

    if (_elems.useIndexableFacets) {
      comp.buildIndexableFacets(false, { key: key, value: value }, isPriceFacet);
    }
    comp.update(true);
  };

  /**
   * Sends the facets to the product list page.
   *
   * Mobile updates are only being sent if the 'force' parameter is set to true.
   *
   * @param {boolean} force forces the facets to be update (mobile only)
   */
  comp.update = function (force) {
    // don' update if it's mobile and non-forcing
    if (!force && $window.document.documentElement.clientWidth < _breakpoint) {
      return;
    }

    /**
     * If no longer in mobile mode, make sure that all elements are accessible to the keyboard
     */
    if ($window.document.documentElement.clientWidth > _breakpoint) {
      _mobile.show && comp.close();
    }

    if (comp.changes) {
      app.publish(_channels.update, {facetFilters: comp.facets.join('|')}, comp.hasIndexableFacets);
      _elems.error.setAttribute(_attr.show, 'false');
    }
  };

  /**
   * Displays an alert at the bottom of the screen.
   */
  comp.error = function () {
    _elems.error.setAttribute(_attr.show, 'true');
    _elems.error.removeAttribute(_attr.ariaHidden);
    _elems.error.style.bottom = `${_elems.saveContainer.offsetHeight}px`;

    $window.setTimeout(() => {
      _elems.error.setAttribute(_attr.show, 'false');
      _elems.error.setAttribute(_attr.ariaHidden, 'true');
    }, _errorDuration);

    app.publish('tracking/record', 'responsiveFacets', 'error', 'new page load');
  };

  comp.fastTrackSwitchChange = function () {
    let index = parseInt(_elems.fastTrackSwitch.getAttribute('data-index'));
    let switchState = _elems.fastTrackSwitch.getAttribute('data-switch-enabled');
    _elems.checkboxes[index].checked = switchState === 'false';
    comp.facetClick(index, false);
  };

  comp.checkAndUpdateFastTrackCookie = function (facetIndex, categoryIndex) {
    let index = 0;
    let fastTrackFacetExists = false;
    for (let checkbox of _elems.checkboxes) {
      if (checkbox.classList.contains(_selectors.fastTrackCheckbox)) {
        fastTrackFacetExists = true;
        break;
      }
      index++;
    }

    if (fastTrackFacetExists) {
      if (facetIndex !== null && facetIndex === index) {
        comp.setCookie('fastTrackSwitchCookie', _elems.checkboxes[index].checked ? 'enableSwitch' : 'disableSwitch');
      } else if (categoryIndex) {
        let sectionIndex = 0;
        for (let sectionContainer of _elems.sectionContainers) {
          if (sectionContainer.classList.contains(_selectors.fastTrackFacetCategory)) {
            break;
          }
          sectionIndex++;
        }

        if (sectionIndex === categoryIndex) {
          comp.setCookie('fastTrackSwitchCookie', 'disableSwitch');
        }

      } else if (_elems.checkboxes[index].checked) {
        comp.setCookie('fastTrackSwitchCookie', 'disableSwitch');
      }
    }
  };

  comp.getFilterType = (key) => {
    let type
    if (key === _facetKeys.brand) {
      type = 'brand';
    } else if (key === _facetKeys.colour) {
      type = 'colour';
    } else if (key === _facetKeys.size) {
      type = 'size';
    }  else if (key === _facetKeys.type) {
      type = 'type';
    } else {
      type = 'other';
    }
    return type
  }

  comp.setCookie = (name, value) => {
    let path = '/';
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    document.cookie = name + '=' + value + '; expires=' + expirationDate.toUTCString() + '; path=' + path;
  };

  comp.skipToProductSection = () => {
    _elems.productTitle && accessibilityFocusHelper.focusAndScroll(_elems.productTitle);
  };

  return comp;
});
